import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';

import { AppHeader } from './AppHeader.js';
import { MainWithBackground } from './Main.js';
import { TemplateDocumentHead } from './TemplateDocumentHead.js';

export function TemplateLanding({
  pageName,
  children,
  fullHeight,
}: {
  pageName: string;
  children: React.ReactNode;
  fullHeight?: boolean;
}) {
  return (
    <MainWithBackground>
      <TemplateDocumentHead title={pageName} />
      <AppHeader />
      <div
        className={clsx({
          'h-full': fullHeight,
        })}
      >
        {children}
      </div>
    </MainWithBackground>
  );
}
