import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { Fragment } from 'react';

import { trpc } from '@/utils/trpcClient.js';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, HomeIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';

const userNavigation = [
  [
    { name: 'Dashboard', href: '/home' },
    { name: 'Edit Profile', href: '/profile' },
  ],
  [{ name: 'Sign out', href: '/logout' }],
  [{ name: 'Contact', href: 'https://forms.gle/Z48EUgzCutQdK9wR9' }],
];

const loggedOutUserNavigation = [
  [
    { name: 'Sign Up', href: '/signup' },
    { name: 'Login', href: '/login' },
  ],
  [{ name: 'Contact', href: 'https://forms.gle/Z48EUgzCutQdK9wR9' }],
];

export const UserMenu = () => {
  const meQuery = trpc.user.me.useQuery();

  return (
    <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
      <div className="flex-1" />
      <div className="flex items-center gap-x-4 lg:gap-x-6">
        <Menu as="div" className="relative">
          <Menu.Button className="-m-1.5 flex items-center p-1.5">
            <span className="sr-only">Open user menu</span>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              className="h-8 w-8 rounded-full bg-gray-50"
              src={meQuery.data?.user.avatar ?? '/assets/defaultAvatars/user.svg'}
              alt=""
              width="32"
              height="32"
            />
            <span className="flex items-center">
              <span
                className="ml-4 text-sm font-semibold leading-6 text-gray-900 dark:text-gray-50"
                aria-hidden="true"
              >
                {meQuery.data?.user.name}
              </span>
              <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white dark:bg-gray-800 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none divide-y divide-gray-100">
              {(meQuery.isFetched && meQuery.data?.user.type === 'registered'
                ? userNavigation
                : loggedOutUserNavigation
              ).map((items, index) => (
                <div key={index} className="py-1">
                  {items.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <a
                          href={item.href}
                          className={clsx(
                            active ? 'bg-brand-dark-pink' : '',
                            'block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-gray-50 hover:text-white',
                          )}
                        >
                          {item.name}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export const AppHeader = () => {
  return (
    <div className={clsx('mx-auto relative w-full top-0 h-16')}>
      <div className="flex h-16 shrink-0 items-center gap-x-4 bg-gray-100 dark:bg-gray-900 px-4 sm:gap-x-6 sm:px-6 lg:px-8">
        <Link href="/home">
          <Image
            className="mx-auto h-6 w-auto"
            src="/assets/logos/dark.svg"
            alt="3DPage.io"
            width={512}
            height={88.2}
            priority
          />
        </Link>
        <UserMenu />
      </div>
    </div>
  );
};
