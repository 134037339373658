import type { AppType } from 'next/app';

import { trpc } from '@/utils/trpcClient.js';

import '../styles/globals.css';

const ThreeDPage: AppType<{}> = ({ Component, pageProps }) => {
  return <Component {...pageProps} />;
};

// ThreeDPage.getInitialProps = async ({ ctx }) => {
//   return {
//     session: await getSession(ctx),
//   };
// };

export default trpc.withTRPC(ThreeDPage);
