'use client';

import superjson from 'superjson';

import { AppRouter } from '@/server/trpcAppRouter.js';
import { createWSClient, loggerLink, splitLink, wsLink } from '@trpc/client';
import { httpBatchLink } from '@trpc/client';
import { createTRPCNext } from '@trpc/next';

function getBaseUrl() {
  if (typeof window !== 'undefined') {
    // In the browser, we return a relative URL
    return '';
  }
  return process.env.NEXT_PUBLIC_HOST;
}

export const trpc = createTRPCNext<AppRouter>({
  config({ ctx }) {
    /**
     * If you want to use SSR, you need to use the server's full URL
     * @link https://trpc.io/docs/ssr
     */

    return {
      /**
       * @link https://trpc.io/docs/client/links
       */
      links: [
        // adds pretty logs to your console in development and logs errors in production
        loggerLink({
          enabled: (opts) =>
            (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') ||
            (opts.direction === 'down' && opts.result instanceof Error),
        }),
        splitLink({
          condition(op) {
            return (
              typeof window === 'undefined' || // if server side
              op.context?.forceHTTP === true // or if the client code is asking for an http connection
            );
          },
          // when condition is true, use normal request
          true: httpBatchLink({
            url: `${getBaseUrl()}/api/trpc`,
            headers() {
              if (!ctx?.req?.headers) {
                return {};
              }
              // on ssr, forward client's headers to the server
              return {
                ...ctx.req.headers,
                'x-ssr': '1',
              };
            },
          }),
          // when condition is false, use batching
          // also lets hard prevent wsLink on the server from getting created
          false:
            typeof window === 'undefined'
              ? []
              : wsLink<AppRouter>({
                  client: createWSClient({
                    url: process.env.NEXT_PUBLIC_WS_URL ?? '',
                  }),
                }),
        }),
      ],
      /**
       * @link https://trpc.io/docs/data-transformers
       */
      transformer: superjson,
      /**
       * @link https://tanstack.com/query/v4/docs/react/reference/QueryClient
       */
      queryClientConfig: { defaultOptions: { queries: { staleTime: 60 } } },
    };
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  ssr: true,
});
