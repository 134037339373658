import Head from 'next/head';

export const TemplateDocumentHead = (props: { title: string }) => {
  return (
    <Head>
      <title key="title">{`[3d]page - ${props.title}`}</title>
      <link rel="apple-touch-icon" sizes="180x180" href="/assets/logos/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/assets/logos/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/assets/logos/favicon-16x16.png" />
      <link rel="manifest" href="/manifest.json" />
      <link rel="mask-icon" href="/assets/logos/safari-pinned-tab.svg" color="#01273e" />
      <meta name="theme-color" content="#01273e" />
    </Head>
  );
};
