import clsx from 'clsx';

export const MainWithBackground = (
  props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>,
) => {
  const { children, className, ...mainProps } = props;
  return (
    <main className={clsx('dark:bg-gray-900 bg-gray-100 h-full', className)} {...mainProps}>
      {children}
    </main>
  );
};
