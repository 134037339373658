import Link from 'next/link.js';
import { SVGProps } from 'react';

const navigation = [
  {
    name: 'Discord',
    href: 'https://discord.gg/6xNu9FyBWR',
    icon: (props: SVGProps<SVGSVGElement>) => (
      <svg viewBox="0 0 127.14 96.36" fill="currentColor" {...props}>
        <path d="M107.7 8.07A105.15 105.15 0 0081.47 0a72.06 72.06 0 00-3.36 6.83 97.68 97.68 0 00-29.11 0A72.37 72.37 0 0045.64 0a105.89 105.89 0 00-26.25 8.09C2.79 32.65-1.71 56.6.54 80.21a105.73 105.73 0 0032.17 16.15 77.7 77.7 0 006.89-11.11 68.42 68.42 0 01-10.85-5.18c.91-.66 1.8-1.34 2.66-2a75.57 75.57 0 0064.32 0c.87.71 1.76 1.39 2.66 2a68.68 68.68 0 01-10.87 5.19 77 77 0 006.89 11.1 105.25 105.25 0 0032.19-16.14c2.64-27.38-4.51-51.11-18.9-72.15zM42.45 65.69C36.18 65.69 31 60 31 53s5-12.74 11.43-12.74S54 46 53.89 53s-5.05 12.69-11.44 12.69zm42.24 0C78.41 65.69 73.25 60 73.25 53s5-12.74 11.44-12.74S96.23 46 96.12 53s-5.04 12.69-11.43 12.69z"></path>
      </svg>
    ),
  },
  {
    name: 'X',
    href: 'https://x.com/parrissays',
    icon: (props: SVGProps<SVGSVGElement>) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
      </svg>
    ),
  },
];

export function AppFooter() {
  return (
    <footer className="bg-gray-50 dark:bg-gray-950">
      <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          {navigation.map((item) => (
            <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="mt-8 md:order-1 md:mt-0">
          <div className="text-center md:text-left text-xs leading-5 text-gray-500">
            <div className="mb-2">
              <Link className="underline" href="/">
                Home
              </Link>{' '}
              |{' '}
              <Link className="underline" href="/roadmap">
                Roadmap
              </Link>{' '}
              |{' '}
              <Link
                className="underline"
                href="https://forms.gle/qX9XU3aHNTZeyFC37"
                target="_blank"
              >
                Bugs/Ideas?
              </Link>{' '}
              |{' '}
              <Link
                className="underline"
                href="https://forms.gle/Z48EUgzCutQdK9wR9"
                target="_blank"
              >
                Contact
              </Link>{' '}
            </div>
            &copy; {new Date().getFullYear()} [3d]page.com & Beta Zeta Inc. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}
